button {
    @apply bg-tertiary rounded-md shadow-lg p-2;

    &:hover {
        @apply bg-tertiary-dark;
    }
}

/* Scroll Bars */
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    //   background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

#mainContainer {
    display: grid;
    grid-template-columns: 35vw 50vw 15vw;

    background-image: radial-gradient(circle, rgb(12 74 110) 1px, rgba(0, 0, 0, 0) 1px);
    background-size: 40px 40px;

    @media (max-width: 1300px) {
        display: block;
    }
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 32px;

    @media (min-width: 500px) {
        flex-direction: column;
        gap: 1rem;
        margin: 0;
    }
}

.close {
    position: absolute;
    top: -.5rem;
    right: .5rem;
    cursor: pointer;
    font-size: 2rem;
    font-weight: bold;
    color: white;
    visibility: hidden;
    opacity: 0;
    transition: 300ms;

    &:hover {
        color: #ff0000;
    }
}

*:hover>.close {
    visibility: visible;
    opacity: 1;
}

.indicator {
    transition: 500ms;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    right: 50%;

    // Rounded on left, square on right
    border-radius: 50% 0 0 50%;
}

.indicator-false {
    right: 0;

    // Rounded on right, square on left
    border-radius: 0 50% 50% 0;
}



.variable {
    transition: 300ms;
    max-height: 40px;
    /* height:9999px; */
    overflow: hidden;
    background-color: rgba(12, 74, 110, .05);
    backdrop-filter: blur(1px);

    .editableContainer {
        visibility: hidden;
        transition: 300ms;
        max-height: 0;
        opacity: 0;
    }

    &:hover {
        background-color: rgba(12, 74, 110);
        max-height: 9999px;

        .padder {
            max-height: 20px;
        }

        .editableContainer {
            visibility: visible;
            max-height: 26px;
            opacity: 1;
        }
    }
}

.padder {
    transition: 300ms;
    height: 20px;
    max-height: 0;
    width: 100%;
}

.editable {
    all: unset;
    padding: 0;
    margin: 1px 0;
    background-color: none;
    height: min-content;
    font-weight: bold;

    &:hover,
    &:focus {
        color: black;
        margin: 0;
        background-color: #f5f5f5;
        border: 1px solid black;
        border-radius: 5px;
        font-weight: normal;
    }
}

#fakeSpan {
    position: absolute;
    left: -9999px;
    display: inline-block;
    min-width: 6em;
    font-weight: bold;
    font-size: medium;
}

.loader {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    margin-left: 4rem;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.toastClose {
    animation: toastClose 1000ms forwards;
}

@keyframes toastClose {
    0% {
        opacity: 1;
        margin-left: 0;
    }

    100% {
        opacity: 0;
        margin-left: 50vw;
    }
}

.function {
    max-height: 64px;
    transition: 300ms;

    .picker {
        visibility: hidden;
        transition: 300ms;
        opacity: 0;
    }

    &:hover {
        @apply bg-tertiary;
        max-height: 9999px;

        .picker {
            visibility: visible;
            opacity: 1;
        }
    }
}

@media (max-width: 1000px) {
    #graphPageContainer {
        display: block;
        padding-bottom: 4rem;
    }

    #functionContainer {
        @apply container mx-auto h-min py-8 rounded-md;

        footer {
            margin-top: 1rem;
        }
    }

    #graphContainer {
        @apply mx-auto;
        width: 90vw;
        height: 90vw;
    }
}